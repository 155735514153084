import TablePagination from '@mui/material/TablePagination'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import DangerousIcon from '@mui/icons-material/Dangerous'
const CryptoJS = require('crypto-js')
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import * as XLSX from 'xlsx/xlsx.mjs'
import { alpha } from '@mui/material/styles'
import { visuallyHidden } from '@mui/utils'
import EditOffIcon from '@mui/icons-material/EditOff'
import FilterListIcon from '@mui/icons-material/FilterList'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import CachedIcon from '@mui/icons-material/Cached'
import LockIcon from '@mui/icons-material/Lock'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import PropTypes from 'prop-types'
import { tablePaginationClasses as classes } from '@mui/base/TablePagination'
import { styled } from '@mui/system'
import {
  CAlert,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CContainer,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavbar,
  CNavbarNav,
  CNavbarToggler,
  CRow,
  CSpinner,
} from '@coreui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save'
import { openModal } from 'src/redux/slices/utilSlice/modalSlice'
import { ReactSpreadsheetImport } from 'react-spreadsheet-import'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardHeader,
  CardMedia,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'
import { getComparator, stableSort } from 'src/utils/filter'
import { useDisclosure } from '@chakra-ui/react'
import { Dropdown, DropdownDivider } from 'react-bootstrap'

import { changeYear, getYearList } from 'src/redux/slices/tableSlice/yearSlice'
import { getSpecialityList } from 'src/redux/slices/teachingSlice/specialitySlice'
import { updateProfileTeacher } from 'src/redux/slices/userSlice/authSlice'
import dayjs from 'dayjs'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { getAllMessages } from 'src/redux/slices/userSlice/messageSlice'
import renderHTML from 'react-render-html'
import ChangePassword from 'src/components/profile/ChangePassword'

const ChangePasswordForm = () => {
  const { teacher } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const lang = teacher?.LANGUE_DEFAUT
  useEffect(() => {
    dispatch(
      openModal({
        title:
          lang === 'Français'
            ? 'Votre mot de passe ne respecte pas les exigences de sécurité'
            : 'Your password does not meet the security requirements',
        componentName: 'ChangePassword',
        size: 'lg',
      }),
    )
  }, [dispatch])

  return (
    <>
      <CRow>
        <CCol xs={12}></CCol>
      </CRow>
    </>
  )
}

const blue = {
  200: '#A5D8FF',
  400: '#3399FF',
}

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
}

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
    
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
    font-size:14px;
  }

  & .${classes.select}{
    padding: 2px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 50px;
    background-color: transparent;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    font-size:14px;

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      font-size:14px;
    }

    &:focus {
      outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      font-size:14px;
    }
  }

  & .${classes.displayedRows} {
    margin: 0;
    font-size:14px;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    padding: 2px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 50px;
    text-align: right;
  }

  & .${classes.actions} > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 4px;
    background-color: transparent;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    }

    &:focus {
      outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
    }

    &:disabled {
      opacity: 0.3;
    }
  }
  `,
)

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export default ChangePasswordForm
