import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  statsData: [],
  error: '',
}

export const getDashboardStats = createAsyncThunk(
  'stats/getDashboardStats',
  async ({ selectedYear }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      //console.log(token)
      const res = await request(token).get(`/api/teachers/getDashboardTeacherStats`, {
        params: { annee_universitaire: selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardStats.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getDashboardStats.fulfilled, (state, action) => {
        return {
          ...state,
          statsData: action.payload,
        }
      })
      .addCase(getDashboardStats.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default statsSlice.reducer
