import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import request from 'src/utils/request'
import { toast } from 'react-toastify'
import { jwtDecode } from 'jwt-decode'

export const getTeacherByID = createAsyncThunk(
  'auth/getTeacherByID',
  async ({ ID_ENS }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).get(`/api/teachers/teacher`, {
        params: { ID_ENS },
      })
      //console.log(res.data)
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const changeTeacherPassword = createAsyncThunk(
  'auth/changeTeacherPassword',
  async ({ ID_ENS, password, newPassword, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res1 = await request(token).put(`/api/teachers/change-password`, {
        ID_ENS,
        password,
        newPassword,
      })
      const message =
        lang === 'Français'
          ? 'Votre mot de passe a été modifié avec succès'
          : 'Your password updated successfully'
      toast.success(message)
      const data = await res1.data

      return data
    } catch (err) {
      if (err.response.status === 400) {
        toast.error(
          lang === 'Français'
            ? `Erreur ! Votre ancien mot de passe est incorrect`
            : `Error ! Your old password is incorrect`,
        )
      } else {
        toast.error(
          lang === 'Français'
            ? `Erreur ! Votre mot de passe n'a pa pu être modifié`
            : `Error ! Your password could not be changed`,
        )
      }
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const checkTeacher = createAsyncThunk(
  'auth/checkTeacher',
  async ({ id, type }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).get(`/api/teachers/auth`, {
        params: { id, type },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const changeLanguage = createAsyncThunk(
  'auth/changeLanguage',
  async ({ id_ens, language, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res1 = await request(token).put(`/api/teachers/changeLanguage`, {
        id_ens,
        language,
      })
      const res = await request(token).get(`/api/teachers/auth`, {
        params: { id: id_ens, type: 'id' },
      })
      const data = await res.data

      localStorage.setItem('teacherInfo', JSON.stringify(data))
      lang === 'Français'
        ? toast.success('Profil modifié avec succès')
        : toast.success('Profile updated successfully')
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const updateTeacher = createAsyncThunk(
  'auth/updateTeacher',
  async ({ formData, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res1 = await request(token).put(`/api/teachers/teacher`, formData, {
        headers: {
          'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      const message =
        lang === 'Français'
          ? 'Votre profil a été modifié avec succès'
          : 'Your profile updated successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      console.log(err)
      const message =
        lang === 'Français'
          ? `Erreur Votre profil n'a pa pu être modifié`
          : `Error ! Your profile could not be modified`
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const getTeacherByEmail = createAsyncThunk(
  'auth/getTeacherByEmail',
  async ({ MAIL_PRO_ENS }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).get(`/api/teachers/profile`, {
        params: { MAIL_PRO_ENS },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const updateProfileTeacher = createAsyncThunk(
  'auth/updateProfileTeacher',
  async ({ viewProfile, ID_ENS, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res1 = await request(token).put(`/api/teachers/viewProfile`, viewProfile)
      const res = await request(token).get(`/api/teachers/auth`, {
        params: { id: ID_ENS, type: 'id' },
      })
      const data = await res.data
      lang === 'Français'
        ? toast.success('Profil modifié avec succès')
        : toast.success('Profile updated successfully')
      localStorage.setItem('teacherInfo', JSON.stringify(data))
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

function vertifyToken() {
  try {
    if (!localStorage.getItem('teacherInfo')) {
      return null
    } else {
      const token = JSON.parse(localStorage.getItem('teacherInfo')).token
      if (jwtDecode(token).exp * 1000 < Date.now()) {
        localStorage.removeItem('teacherInfo')
        return null
      } else {
        return JSON.parse(localStorage.getItem('teacherInfo'))
      }
    }
  } catch (error) {
    console.log(error)
    localStorage.removeItem('teacherInfo')
    return null
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    teacher: vertifyToken(),
    //user: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
    currentTeacherData: [],
    registerMessage: null,
    isLoading: false,
    isSuccess: false,
    error: false,
  },
  reducers: {
    login(state, action) {
      state.teacher = action.payload
      state.registerMessage = null
    },
    logout(state) {
      state.teacher = null
    },
    register(state, action) {
      state.registerMessage = action.payload
    },
    /*    setUsername(state, action) {
      state.teacher.username = action.payload
    },*/
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeacherByEmail.pending, (state) => {
        return {
          ...state,
        }
      })
      .addCase(getTeacherByEmail.fulfilled, (state, action) => {
        return {
          ...state,
          currentTeacherData: action.payload,
        }
      })
      .addCase(getTeacherByEmail.rejected, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(getTeacherByID.pending, (state) => {
        return {
          ...state,
        }
      })
      .addCase(getTeacherByID.fulfilled, (state, action) => {
        return {
          ...state,
          currentTeacherData: action.payload,
        }
      })
      .addCase(getTeacherByID.rejected, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(changeTeacherPassword.fulfilled, (state, action) => {
        localStorage.removeItem('teacherInfo')
        return {
          ...state,
        }
      })
      .addCase(updateProfileTeacher.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(updateProfileTeacher.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          teacher: action.payload,
          error: '',
        }
      })
      .addCase(updateProfileTeacher.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(changeLanguage.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(changeLanguage.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          teacher: vertifyToken(),
          error: '',
        }
      })
      .addCase(changeLanguage.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

const authReducer = authSlice.reducer
const authActions = authSlice.actions

export { authActions, authReducer }
