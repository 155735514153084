import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  countryData: [],
  error: '',
}

export const getCountriesList = createAsyncThunk(
  'country/getCountriesList',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).get(`/api/teachers/country`)
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountriesList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getCountriesList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          countryData: action.payload,
          error: '',
        }
      })
      .addCase(getCountriesList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default countrySlice.reducer
