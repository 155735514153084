import React, { useEffect, useState } from 'react'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Link, useParams, useNavigate } from 'react-router-dom'
import validator from 'validator'
import { toast, ToastContainer } from 'react-toastify'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPassword, loginUser, resetPassword } from 'src/redux/apiCalls/authApiCall'
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCheckbox,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from 'mdb-react-ui-kit'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import { getMinInstitutionInfo } from 'src/redux/slices/tableSlice/institutionSlice'
import { Col, Row } from 'react-bootstrap'
import { min } from 'moment'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const ActivateAccount = () => {
  const { id, token } = useParams()
  const navigate = useNavigate()

  //const { lang } = useSelector((state) => state.language)
  const { minInstitutionData } = useSelector((state) => state.institution)
  const [passwords, setPasswords] = useState({
    newPassword: '',
    confirmPassword: '',
  })

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [login, setLogin] = useState('')

  const [formValues, setFormValues] = useState({
    newPassword: {
      error: true,
      errorMessage:
        minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
          ? `Le mot de passe doit respecter des exigences de complexité`
          : 'The password must comply with complexity requirements',
    },
    confirmPassword: {
      error: true,
      errorMessage:
        minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
          ? `Le mot de passe et sa confirmation ne sont pas identiques`
          : 'The password and its confirmation are not identical',
    },
  })
  const dispatch = useDispatch()

  const handleInputChange = (e) => {
    e.preventDefault()
    setPasswords({ ...passwords, [e.target.name]: e.target.value })
  }

  function validate_password(password) {
    let check = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    return password.match(check)
  }

  useEffect(() => {
    setFormValues({
      ...formValues,
      confirmPassword:
        passwords?.newPassword === passwords?.confirmPassword ||
        passwords?.confirmPassword.trim() === ''
          ? {
              error: false,
              errorMessage:
                minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
                  ? `Le mot de passe et sa confirmation ne sont pas identiques`
                  : 'The password and its confirmation are not identical',
            }
          : {
              error: true,
              errorMessage:
                minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
                  ? `Le mot de passe et sa confirmation ne sont pas identiques`
                  : 'The password and its confirmation are not identical',
            },
      newPassword:
        validate_password(passwords?.newPassword) || passwords?.newPassword.trim() === ''
          ? {
              error: false,
              errorMessage:
                minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
                  ? `Le mot de passe doit respecter des exigences de complexité`
                  : 'The password must comply with complexity requirements',
            }
          : {
              error: true,
              errorMessage:
                minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
                  ? `Le mot de passe doit respecter des exigences de complexité`
                  : 'The password must comply with complexity requirements',
            },
    })
  }, [passwords])

  useEffect(() => {
    dispatch(getMinInstitutionInfo())
  }, [dispatch])

  function Copyright() {
    return (
      <Grid sx={{ padding: 2 }}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://universco.fcsdz.com/">
            UNIVERSCO Web App 2024
          </Link>
        </Typography>
      </Grid>
    )
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    ;(await dispatch(
      resetPassword({
        MAIL_PRO_ENS: id,
        password: passwords.newPassword,
        token,
        lang: minInstitutionData?.LANGUE_DEFAUT_ETAB,
      }),
    )) === 200
      ? navigate('/')
      : null
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar sx={{ background: '#000000' }} position="static">
          <Toolbar>
            <>
              <CardMedia
                component="img"
                sx={{
                  width: 200,
                  height: 60,
                }}
                image={'/logo_universco.png'}
                alt="Logo"
              />

              <Typography
                sx={{ flexGrow: 1, display: { xs: 'none', lg: 'block', xl: 'none' } }}
                variant="h5"
                component="div"
              >
                {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
                  ? minInstitutionData?.LIB_ETAB?.length > 90
                    ? minInstitutionData?.LIB_ETAB_C
                    : minInstitutionData?.LIB_ETAB
                  : minInstitutionData?.LIB_ETAB_EN?.length > 90
                  ? minInstitutionData?.LIB_ETAB_EN_C
                  : minInstitutionData?.LIB_ETAB_EN}
              </Typography>
              <Typography
                sx={{ flexGrow: 1, display: { xs: 'block', lg: 'none', xl: 'block' } }}
                variant="h5"
                component="div"
              >
                {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
                  ? minInstitutionData?.LIB_ETAB_C
                  : minInstitutionData?.LIB_ETAB_EN_C}
              </Typography>

              <Button color="inherit">
                <Link
                  to={window.location.origin.replace('teachers-', '')}
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français' ? 'Accueil' : 'Home'}
                </Link>
              </Button>
            </>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        sx={{ margin: 1 }}
      >
        <Grid item>
          <CardMedia
            component="img"
            sx={{
              width: parseInt(process.env.REACT_APP_LOGO_WIDTH) || 10,
              height: parseInt(process.env.REACT_APP_LOGO_HEIGHT) || 10,
            }}
            image={'/logo_institution.png'}
            alt="Logo"
          />
        </Grid>
      </Grid>

      <Box
        component="form"
        sx={{
          maxWidth: '500px',
          margin: 'auto',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
          backgroundColor: 'white',
          marginTop: 2,
        }}
      >
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
            ? `Portail des enseignants - Activer votre compte`
            : 'Teachers portal - Activate your account'}
        </Typography>
        <Grid container spacing={2} mb={2}>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              value={id || ''}
              label={minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français' ? `Email` : 'Mail'}
              margin="normal"
              variant="standard"
              onChange={handleInputChange}
              name="username"
              disabled={true}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={12} xs={12}>
            <TextField
              label={
                minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
                  ? 'Nouveau mot de passe'
                  : 'New password'
              }
              name="newPassword"
              fullWidth
              type={showNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowNewPassword(!showNewPassword)
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={passwords?.newPassword || ''}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              onChange={handleInputChange}
              error={formValues.newPassword.error}
              helperText={formValues.newPassword.error && formValues.newPassword.errorMessage}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item md={12} xs={12}>
            <TextField
              label={
                minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
                  ? 'Confirmer le nouveau mot de passe'
                  : 'Confirm the new password'
              }
              name="confirmPassword"
              fullWidth
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword)
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={passwords?.confirmPassword || ''}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              onChange={handleInputChange}
              error={formValues.confirmPassword.error}
              helperText={
                formValues.confirmPassword.error && formValues.confirmPassword.errorMessage
              }
            />
          </Grid>
        </Grid>

        {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français' ? (
          <>
            <Typography variant="body2">Votre mot de passe doit contenir :</Typography>
            <Typography variant="body2">- au minimum 8 caractères,</Typography>
            <Typography variant="body2">- au moins une lettre miniscule,</Typography>
            <Typography variant="body2">- au moins une lettre majuscule,</Typography>
            <Typography variant="body2">- au moins un chiffre,</Typography>
            <Typography variant="body2">- au moins un caractère spécial.</Typography>
          </>
        ) : (
          <>
            <Typography variant="body2">Your password must contain :</Typography>
            <Typography variant="body2">- at least 8 characters,</Typography>
            <Typography variant="body2">- at least one miniscule letter,</Typography>
            <Typography variant="body2">- at least one capital letter,</Typography>
            <Typography variant="body2">- at least one digit,</Typography>
            <Typography variant="body2">- at least one special character.</Typography>
          </>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, background: '#000000' }}
          onClick={handleSubmit}
          disabled={
            formValues?.newPassword.error ||
            formValues?.confirmPassword.error ||
            passwords.newPassword.trim() === '' ||
            passwords.confirmPassword.trim() === ''
          }
        >
          {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français' ? `Modifier` : 'Update'}
        </Button>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Link to="/login" variant="body2">
            {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
              ? `S'authentifier`
              : 'Authenticate'}
          </Link>
        </Box>
      </Box>
      <Copyright />
    </>
  )
}

export default ActivateAccount
