import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isAllLoading: false,
  isSuccess: false,
  messageData: [],
  allMessageData: [],
  error: '',
}

export const getTeacherMessage = createAsyncThunk(
  'message/getTeacherMessage',
  async ({ MAIL_PRO_ENS, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).get(`/api/teachers/teacherMessage`, {
        params: {
          MAIL_PRO_ENS,
        },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      toast.error(message)

      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getAllMessages = createAsyncThunk(
  'message/getAllMessages',
  async ({ MAIL_PRO_ENS, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).get(`/api/teachers/allMessages`, {
        params: {
          MAIL_PRO_ENS,
        },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      toast.error(message)

      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeacherMessage.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getTeacherMessage.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          messageData: action.payload,
          error: '',
        }
      })
      .addCase(getTeacherMessage.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(getAllMessages.pending, (state) => {
        return {
          ...state,
          isAllLoading: true,
        }
      })
      .addCase(getAllMessages.fulfilled, (state, action) => {
        return {
          ...state,
          isAllLoading: false,
          isSuccess: true,
          allMessageData: action.payload,
          error: '',
        }
      })
      .addCase(getAllMessages.rejected, (state, action) => {
        return {
          ...state,
          isAllLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default messageSlice.reducer
