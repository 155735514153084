import React, { useEffect, useState } from 'react'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Link } from 'react-router-dom'
import validator from 'validator'
import { toast, ToastContainer } from 'react-toastify'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser, requestAccount } from 'src/redux/apiCalls/authApiCall'
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCheckbox,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from 'mdb-react-ui-kit'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import { getMinInstitutionInfo } from 'src/redux/slices/tableSlice/institutionSlice'
import { Col, Row } from 'react-bootstrap'
import { min } from 'moment'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const RequestAccount = () => {
  //const { lang } = useSelector((state) => state.language)
  const { minInstitutionData } = useSelector((state) => state.institution)

  const [login, setLogin] = useState('')

  const [formValues, setFormValues] = useState({
    username: {
      error: false,
      errorMessage:
        minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
          ? `Votre mail n'est pas valide`
          : 'Your email is not valid',
    },
  })
  const dispatch = useDispatch()

  const handleInputChange = (event) => {
    const { name, value } = event.target
    switch (name) {
      case 'username':
        !validator.isEmail(value)
          ? setFormValues({
              ...formValues,
              [name]: {
                error: true,
                errorMessage:
                  minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
                    ? `Votre email n'est pas valide`
                    : 'Your email is not valid',
              },
            })
          : setFormValues({
              ...formValues,
              [name]: { error: false, errorMessage: '' },
            })

        break
    }
    setLogin(value.trim())
  }

  useEffect(() => {
    dispatch(getMinInstitutionInfo())
  }, [dispatch])

  function Copyright() {
    return (
      <Grid sx={{ padding: 2 }}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://universco.fcsdz.com/">
            UNIVERSCO Web App 2024
          </Link>
        </Typography>
      </Grid>
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(requestAccount({ user: login, lang: minInstitutionData?.LANGUE_DEFAUT_ETAB }))
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar sx={{ background: '#000000' }} position="static">
          <Toolbar>
            <>
              <CardMedia
                component="img"
                sx={{
                  width: 200,
                  height: 60,
                }}
                image={'/logo_universco.png'}
                alt="Logo"
              />

              <Typography
                sx={{ flexGrow: 1, display: { xs: 'none', lg: 'block', xl: 'none' } }}
                variant="h5"
                component="div"
              >
                {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
                  ? minInstitutionData?.LIB_ETAB?.length > 90
                    ? minInstitutionData?.LIB_ETAB_C
                    : minInstitutionData?.LIB_ETAB
                  : minInstitutionData?.LIB_ETAB_EN?.length > 90
                  ? minInstitutionData?.LIB_ETAB_EN_C
                  : minInstitutionData?.LIB_ETAB_EN}
              </Typography>
              <Typography
                sx={{ flexGrow: 1, display: { xs: 'block', lg: 'none', xl: 'block' } }}
                variant="h5"
                component="div"
              >
                {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
                  ? minInstitutionData?.LIB_ETAB_C
                  : minInstitutionData?.LIB_ETAB_EN_C}
              </Typography>

              <Button color="inherit">
                <Link
                  to={window.location.origin.replace('teachers-', '')}
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français' ? 'Accueil' : 'Home'}
                </Link>
              </Button>
            </>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        sx={{ margin: 1 }}
      >
        <Grid item>
          <CardMedia
            component="img"
            sx={{
              width: parseInt(process.env.REACT_APP_LOGO_WIDTH) || 10,
              height: parseInt(process.env.REACT_APP_LOGO_HEIGHT) || 10,
            }}
            image={'/logo_institution.png'}
            alt="Logo"
          />
        </Grid>
      </Grid>

      <Box
        component="form"
        sx={{
          maxWidth: '500px',
          margin: 'auto',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
          backgroundColor: 'white',
          marginTop: 2,
        }}
      >
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
            ? `Portail des enseignants - Activer mon compte`
            : 'Teachers portal - Activate my account'}
        </Typography>

        <TextField
          fullWidth
          label={minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français' ? `Email` : 'Mail'}
          margin="normal"
          variant="standard"
          onChange={handleInputChange}
          name="username"
          error={formValues.username.error}
          helperText={formValues.username.error && formValues.username.errorMessage}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, background: '#000000' }}
          disabled={login === '' || formValues.username.error}
          onClick={handleSubmit}
        >
          {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français' ? `Activer` : 'Activate'}
        </Button>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Link to="/login" variant="body2">
            {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
              ? `S'authentifier`
              : 'Authenticate'}
          </Link>
        </Box>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Link to="/forgot-password" variant="body2">
            {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
              ? `Mot de passe oublié ?`
              : 'Forgot your password ?'}
          </Link>
        </Box>
      </Box>
      <Copyright />
    </>
  )
}

export default RequestAccount
