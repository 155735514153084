import { authActions, getTeacherByEmail } from '../slices/userSlice/authSlice'
import request from '../../utils/request'
import { toast } from 'react-toastify'
import { getMinInstitutionInfo } from '../slices/tableSlice/institutionSlice'
import axios from 'axios'
import { deviceDetect } from 'react-device-detect'
import { redirect, useNavigate } from 'react-router-dom'
const publicIp = require('react-public-ip')

// Login User
export function loginUser(user) {
  return async (dispatch) => {
    try {
      //const resIP = await axios.get('https://geolocation-db.com/json/')
      const public_ipv4 = (await publicIp.v4()) || ''

      //const ipv4 = '192.168.20.1'
      //console.log(ipv4)
      const deviceInfo = deviceDetect()
      //const deviceInfo = 'dev'
      const res = await axios.post(
        `/api/teachers/auth`,
        { user, public_ipv4, deviceInfo },
        {
          baseURL: `${process.env.REACT_APP_SERVER_DOMAIN}`,
          withCredentials: true,
          headers: {
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
            'Content-Type': 'application/json',
          },
        },
      )
      if (res.status == 200) {
        localStorage.setItem('teacherInfo', JSON.stringify(res.data))
        dispatch(authActions.login(res.data))
        dispatch(getTeacherByEmail({ MAIL_PRO_ENS: user?.username }))
        //dispatch(getMinInstitutionInfo())
      } else {
        toast.error(res.data)
      }
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }
}

// Forgot Password
export function forgotPassword({ user, lang }) {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `/api/teachers/forgot-password`,
        { user },
        {
          baseURL: `${process.env.REACT_APP_SERVER_DOMAIN}`,
          withCredentials: true,
          headers: {
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
            'Content-Type': 'application/json',
          },
        },
      )
      toast.success(
        lang === 'Français'
          ? `Veuillez consulter votre mail pour réinitialiser votre mot de passe`
          : `Please check your email to reset your password`,
      )
    } catch (error) {
      //console.log(error)
      switch (error.response.status) {
        case 401:
          toast.error(error.response.data.message)
          break
        case 400:
          toast.error(
            lang === 'Français'
              ? `Erreur ! Il n'y a aucun enseignant avec ce mail`
              : 'Error ! There are no teachers with this email',
          )
          break
        case 404:
          toast.error(
            lang === 'Français'
              ? `Erreur ! L'email n'a pas pu être envoyé`
              : `Error ! The email could not be sent`,
          )
          break
      }
      //toast.error(error.response.data.message)
    }
  }
}

// Request account
export function requestAccount({ user, lang }) {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `/api/teachers/request-account`,
        { user },
        {
          baseURL: `${process.env.REACT_APP_SERVER_DOMAIN}`,
          withCredentials: true,
          headers: {
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
            'Content-Type': 'application/json',
          },
        },
      )
      toast.success(
        lang === 'Français'
          ? `Veuillez consulter votre mail pour activer votre compte`
          : `Please check your email to activate your account`,
      )
    } catch (error) {
      //console.log(error)
      switch (error.response.status) {
        case 401:
          toast.error(error.response.data.message)
          break
        case 400:
          toast.error(
            lang === 'Français'
              ? `Erreur ! Il n'y a aucun enseignant avec ce mail`
              : 'Error ! There are no teachers with this email',
          )
          break
        case 404:
          toast.error(
            lang === 'Français'
              ? `Erreur ! L'email n'a pas pu être envoyé`
              : `Error ! The email could not be sent`,
          )
          break
        case 405:
          toast.error(
            lang === 'Français'
              ? `Votre compte est déjà activé. Réinitialisez votre mot de passe !`
              : `Your account is already activated. Reset your password !`,
          )
          break
      }
      //toast.error(error.response.data.message)
    }
  }
}

// Reset Password
export function resetPassword({ MAIL_PRO_ENS, password, token, lang }) {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `/api/teachers/reset-password`,
        { MAIL_PRO_ENS, password, token },
        {
          baseURL: `${process.env.REACT_APP_SERVER_DOMAIN}`,
          withCredentials: true,
          headers: {
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
            'Content-Type': 'application/json',
          },
        },
      )
      toast.success(
        lang === 'Français'
          ? `Votre mot de passe a été réinitialisé avec succès`
          : `Your password has been successfully reset`,
      )
      //return navigate('/')
      return res.status
    } catch (error) {
      console.log(error)
      switch (error.response.status) {
        case 401:
        case 403:
          toast.error(
            lang === 'Français'
              ? `Erreur de réinitialisation de mot de passe`
              : 'Password reset error',
          )
          break
        case 400:
          toast.error(
            lang === 'Français'
              ? `Erreur ! Il n'y a aucun enseignant avec ce mail`
              : 'Error ! There are no teachers with this email',
          )
          break
        case 401:
          toast.error(error.response.data.message)
          break
      }
      //toast.error(error.response.data.message)
      return error.response.status
    }
  }
}

// Reset Password
export function activateAccount({ MAIL_PRO_ENS, password, token, lang }) {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `/api/teachers/activate-account`,
        { MAIL_PRO_ENS, password, token },
        {
          baseURL: `${process.env.REACT_APP_SERVER_DOMAIN}`,
          withCredentials: true,
          headers: {
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
            'Content-Type': 'application/json',
          },
        },
      )
      toast.success(
        lang === 'Français'
          ? `Votre compte a été activé avec succès`
          : `Your account has been successfully activated`,
      )
      //return navigate('/')
      return res.status
    } catch (error) {
      console.log(error)
      switch (error.response.status) {
        case 401:
        case 403:
          toast.error(
            lang === 'Français'
              ? `Erreur d'activation de votre compte`
              : 'Error activating your account',
          )
          break
        case 400:
          toast.error(
            lang === 'Français'
              ? `Erreur ! Il n'y a aucun enseignant avec ce mail`
              : 'Error ! There are no teachers with this email',
          )
          break
        case 401:
          toast.error(error.response.data.message)
          break
      }
      //toast.error(error.response.data.message)
      return error.response.status
    }
  }
}

// Logout User
export function logoutUser() {
  return async (dispatch) => {
    try {
      //const res = await axios.post(`/api/users/logout`)
      //if (res.status == 200) {
      dispatch(authActions.logout())
      localStorage.removeItem('teacherInfo')
      //localStorage.removeItem('userYear')
      //}
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }
}
