import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  nationalityData: [],
  error: '',
}

export const getNationalitiesList = createAsyncThunk(
  'nationality/getNationalitiesList',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).get(`/api/teachers/nationality`)
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const nationalitySlice = createSlice({
  name: 'nationality',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNationalitiesList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getNationalitiesList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          nationalityData: action.payload,
          error: '',
        }
      })
      .addCase(getNationalitiesList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default nationalitySlice.reducer
