import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isLoadingImport: false,
  isLoadingGrade: false,
  isLoadingCourse: false,
  isLoadingGroup: false,
  isSuccess: false,
  courseData: [],
  courseBySpecialityData: [],
  groupData: [],
  gradeData: [],
  error: '',
  errorImport: '',
  percentage: 0,
}

export const getGroupByTeacherCourse = createAsyncThunk(
  'course/getGroupByTeacherCourse',
  async ({ ID_SPE, ID_SEM, ID_MAT, ID_ENS }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).get(`/api/teachers/groupByTeacherCourse`, {
        params: {
          ID_SPE,
          ID_SEM,
          ID_MAT,
          ID_ENS,
        },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const updateGradesByTeacherCourse = createAsyncThunk(
  'course/updateGradesByTeacherCourse',
  async ({ currentGrades, common, importMode, page, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res1 = await request(token).put(`/api/teachers/gradeByTeacherCourse`, {
        currentGrades,
        common,
        importMode,
        page,
      })
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' note(s) modifiée(s)'
          : res1.data.rowUpdated + ' grades(s) updated'
      toast.success(message)
      //console.log(currentGrades)
      /*const res = await request.get(`/api/student/gradesByStudent`, {
        params: { matricule: currentGrades[0].MATRICULE, id_spe: currentGrades[0].ID_SPE },
      })*/
      const data = await res1.data
      return data
    } catch (err) {
      console.log(err)
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated +
            '/' +
            (err.response.data.rowUpdated + err.response.data.errorCount).toString() +
            ' notes(s) modifiée(s)'
          : err.response.data.rowUpdated +
            '/' +
            (err.response.data.rowUpdated + err.response.data.errorCount).toString() +
            ' grade(s) updated'
      toast.info(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const getGradeByTeacherCourse = createAsyncThunk(
  'course/getGradeByTeacherCourse',
  async ({ ID_SPE, ID_SEM, ID_MAT, GROUPE_ETU, ID_ENS }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).get(`/api/teachers/gradeByTeacherCourse`, {
        params: { ID_SPE, ID_SEM, ID_MAT, GROUPE_ETU, ID_ENS },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getCourseByTeacherSpeciality = createAsyncThunk(
  'course/getCourseByTeacherSpeciality',
  async ({ ID_SPE, ID_ENS, ID_SEM }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).get(`/api/teachers/courseByTeacherSpeciality`, {
        params: { ID_SPE, ID_ENS, ID_SEM },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCourseByTeacherSpeciality.pending, (state) => {
        return {
          ...state,
          isLoadingCourse: true,
        }
      })
      .addCase(getCourseByTeacherSpeciality.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          courseBySpecialityData: action.payload,
          isLoadingCourse: false,
          error: '',
        }
      })
      .addCase(getCourseByTeacherSpeciality.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          isLoadingCourse: false,
          error: action.error.message,
        }
      })
      .addCase(getGroupByTeacherCourse.pending, (state) => {
        return {
          ...state,
          isLoadingGroup: true,
        }
      })
      .addCase(getGroupByTeacherCourse.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          groupData: action.payload,
          isLoadingGroup: false,
          error: '',
        }
      })
      .addCase(getGroupByTeacherCourse.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          isLoadingGroup: false,
          error: action.error.message,
        }
      })
      .addCase(getGradeByTeacherCourse.pending, (state) => {
        return {
          ...state,
          isLoadingGrade: true,
        }
      })
      .addCase(getGradeByTeacherCourse.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          gradeData: action.payload,
          isLoadingGrade: false,
          error: '',
        }
      })
      .addCase(getGradeByTeacherCourse.rejected, (state, action) => {
        return {
          ...state,
          isLoadingGrade: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(updateGradesByTeacherCourse.pending, (state) => {
        return {
          //isLoadingGrade: true,
          ...state,
        }
      })
      .addCase(updateGradesByTeacherCourse.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          //isLoadingGrade: false,
          error: '',
        }
      })
      .addCase(updateGradesByTeacherCourse.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          //isLoadingGrade: false,
          error: action.error.message,
        }
      })
  },
})

export default courseSlice.reducer
