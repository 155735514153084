import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  specialityData: [],
  semesterData: [],
  error: '',
}

export const getSemesterByTeacherSpeciality = createAsyncThunk(
  'speciality/getSemesterByTeacherSpeciality',
  async ({ ID_SPE, ID_ENS }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).get(`/api/teachers/semesterByTeacherSpeciality`, {
        params: { ID_SPE, ID_ENS },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getSpecialityList = createAsyncThunk(
  'speciality/getSpecialityList',
  async ({ ANNEE_UNIVERSITAIRE, ID_ENS }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).get(`/api/teachers/speciality`, {
        params: { ANNEE_UNIVERSITAIRE, ID_ENS },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const specialitySlice = createSlice({
  name: 'speciality',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSpecialityList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getSpecialityList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          specialityData: action.payload,
          error: '',
        }
      })
      .addCase(getSpecialityList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(getSemesterByTeacherSpeciality.pending, (state) => {
        return {
          ...state,
        }
      })
      .addCase(getSemesterByTeacherSpeciality.fulfilled, (state, action) => {
        return {
          ...state,
          semesterData: action.payload,
          error: '',
        }
      })
      .addCase(getSemesterByTeacherSpeciality.rejected, (state, action) => {
        return {
          ...state,
          error: action.error.message,
        }
      })
  },
})

export default specialitySlice.reducer
