import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  yearData: [],
  selectedYear: null,
  lastYear: null,
  closedYear: false,
  error: '',
}

export const getYearList = createAsyncThunk('year/getYearList', async ({ ID_ENS }, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.teacher.token
    const res = await request(token).get(`/api/teachers/year`, {
      params: { ID_ENS },
    })
    const data = await res.data
    return data
  } catch (err) {
    toast.error(err.response.data)
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

/*export const getYearList = createAsyncThunk('year/getYearList', async (_, thunkAPI) => {
  const token = thunkAPI.getState().auth.teacher.token
  const res = await request(token).get(`/api/teachers/year`)
  console.log(res.data)
  const data = await res.data
  return data
})*/

export const changeYear = createAsyncThunk(
  'year/changeYear',
  async ({ year, closedYear, lang }, thunkAPI) => {
    try {
      return { year, closedYear }
    } catch (err) {
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const yearSlice = createSlice({
  name: 'year',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getYearList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getYearList.fulfilled, (state, action) => {
        /*localStorage.getItem('userYear')
          ? null
          : localStorage.setItem('userYear', action.payload[0].ANNEE_UNIVERSITAIRE)*/
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          yearData: action.payload,
          selectedYear: action.payload[0].ANNEE_UNIVERSITAIRE,
          lastYear: action.payload[0].ANNEE_UNIVERSITAIRE,
          closedYear:
            action.payload
              .filter((item) => item?.ANNEE_UNIVERSITAIRE === action.payload[0].ANNEE_UNIVERSITAIRE)
              .map((n) => n.LOCK_ANNEE)[0] === '1'
              ? true
              : false,
          error: '',
        }
      })
      .addCase(getYearList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(changeYear.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(changeYear.fulfilled, (state, action) => {
        //localStorage.setItem('userYear', action.payload.year)
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          selectedYear: action.payload.year,
          closedYear: action.payload.closedYear === '1' ? true : false,
          error: '',
        }
      })
      .addCase(changeYear.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default yearSlice.reducer
