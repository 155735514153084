import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import React, { Component, Suspense, useEffect } from 'react'
import { BrowserRouter, HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr'
import RequestAccount from './pages/forms/login/RequestAccount'
import ActivateAccount from './pages/forms/login/ActivateAccount'
import ChangePasswordForm from './pages/students/ChangePasswordForm'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const theme = createTheme({
  typography: {
    fontFamily: `system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans',
    'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji'`,
  },
})

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./pages/forms/login/Login'))
const ForgotPassword = React.lazy(() => import('./pages/forms/login/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./pages/forms/login/ResetPassword'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  const { teacher } = useSelector((state) => state.auth)

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <ThemeProvider theme={theme}>
            <Routes>
              <Route
                exact
                path="/login"
                name="Login Page"
                element={!teacher ? <Login /> : <Navigate to="/" />}
              />
              <Route
                exact
                path="/forgot-password"
                name="Forgot password"
                element={!teacher ? <ForgotPassword /> : <Navigate to="/" />}
              />

              <Route
                exact
                path="/request-account"
                name="Activate my account"
                element={!teacher ? <RequestAccount /> : <Navigate to="/" />}
              />
              <Route exact path="/reset-password/:id/:token" element={<ResetPassword />}></Route>
              <Route
                exact
                path="/activate-account/:id/:token"
                element={<ActivateAccount />}
              ></Route>
              <Route
                exact
                path="/register"
                name="Register Page"
                element={!teacher ? <Register /> : <Navigate to="/" />}
              />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={!teacher ? <Login /> : <DefaultLayout />} />
            </Routes>
          </ThemeProvider>
        </LocalizationProvider>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
