import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  gradeData: [],
  error: '',
}

export const getGradeList = createAsyncThunk('grade/getGradeList', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.teacher.token
    const res = await request(token).get(`/api/teachers/grade`)
    const data = await res.data
    return data
  } catch (err) {
    toast.error(err.response.data)
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const gradeSlice = createSlice({
  name: 'grade',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGradeList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getGradeList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          gradeData: action.payload,
          error: '',
        }
      })
      .addCase(getGradeList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default gradeSlice.reducer
