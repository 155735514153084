import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  statusTeacherData: [],
  error: '',
}

export const getStatusTeacherList = createAsyncThunk(
  'statusTeacher/getStatusTeacherList',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).get(`/api/teachers/statusTeacher`)
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const statusTeacherSlice = createSlice({
  name: 'statusTeacher',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatusTeacherList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getStatusTeacherList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          statusTeacherData: action.payload,
          error: '',
        }
      })
      .addCase(getStatusTeacherList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default statusTeacherSlice.reducer
