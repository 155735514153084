//import { createStore } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { authReducer } from './slices/userSlice/authSlice'
import institutionReducer from './slices/tableSlice/institutionSlice'
import modalReducer from './slices/utilSlice/modalSlice'
import slideReducer from './slices/utilSlice/slideSlice'
import languageReducer from './slices/utilSlice/languageSlice'
import wilayaReducer from './slices/tableSlice/wilayaSlice'
import countryReducer from './slices/tableSlice/countrySlice'
import nationalityReducer from './slices/tableSlice/nationalitySlice'
import maritalStatusReducer from './slices/tableSlice/maritalStatusSlice'
import gradeReducer from './slices/tableSlice/gradeSlice'
import statusTeacherReducer from './slices/tableSlice/statusTeacherSlice'
import departmentReducer from './slices/tableSlice/departmentSlice'
import yearReducer from './slices/tableSlice/yearSlice'
import emailReducer from './slices/utilSlice/emailSlice'
import specialityReducer from './slices/teachingSlice/specialitySlice'
import courseReducer from './slices/teachingSlice/courseSlice'
import studentReducer from './slices/teachingSlice/studentSlice'
import statsReducer from './slices/tableSlice/statsSlice'
import messageReducer from './slices/userSlice/messageSlice'
import usefulLinksReducer from './slices/tableSlice/usefulLinks'

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    auth: authReducer,
    language: languageReducer,
    institution: institutionReducer,
    modal: modalReducer,
    slide: slideReducer,
    wilaya: wilayaReducer,
    country: countryReducer,
    nationality: nationalityReducer,
    maritalStatus: maritalStatusReducer,
    grade: gradeReducer,
    statusTeacher: statusTeacherReducer,
    department: departmentReducer,
    year: yearReducer,
    email: emailReducer,
    speciality: specialityReducer,
    course: courseReducer,
    student: studentReducer,
    stats: statsReducer,
    message: messageReducer,
    usefulLinks: usefulLinksReducer,
  },
  devTools: process.env.REACT_APP_SERVER_NODE_ENV != 'production',
})

export default store
