import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: '',
}

export const sendEmail = createAsyncThunk('email/sendEmail', async ({ email, lang }, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.teacher.token
    const res = await request(token).post(`/api/teachers/send-email`, { email: email })
    const message =
      lang === 'Français'
        ? 'Votre message a été envoyé avec succès'
        : 'Your message has been sent successfully'
    toast.success(message)
    const data = await res.data
    return data
  } catch (err) {
    const message =
      lang === 'Français'
        ? `Erreur ! Votre message n'a pa pu être envoyé`
        : 'Error ! Your message could not be sent'
    toast.error(message)

    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const setMessageRead = createAsyncThunk(
  'email/setMessageRead',
  async ({ id_con, read }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).put(`/api/teachers/setMessageRead`, { id_con, read })
      const data = await res.data
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const sendGradesByEmail = createAsyncThunk(
  'email/sendGradesByEmail',
  async ({ email, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.teacher.token
      const res = await request(token).post(`/api/teachers/send-grades-email`, email)
      const message =
        lang === 'Français'
          ? 'Vos notes ont été envoyées avec succès'
          : 'Your grades has been sent successfully'
      toast.success(message)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? `Erreur ! Vos notes n'ont pu être envoyées`
          : 'Error ! Your grades could not be sent'
      toast.error(message)

      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendEmail.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          error: '',
        }
      })
      .addCase(sendEmail.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(sendGradesByEmail.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(sendGradesByEmail.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          error: '',
        }
      })
      .addCase(sendGradesByEmail.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default emailSlice.reducer
