import Editor from 'react-simple-wysiwyg'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { closeModal } from 'src/redux/slices/utilSlice/modalSlice'
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { changeTeacherPassword } from 'src/redux/slices/userSlice/authSlice'

const ChangePassword = () => {
  const { teacher } = useSelector((state) => state.auth)
  const lang = teacher?.LANGUE_DEFAUT

  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  })

  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const dispatch = useDispatch()

  const [formValues, setFormValues] = useState({
    oldPassword: {
      error: true,
      errorMessage:
        lang === 'Français'
          ? `L'ancien et le nouveau mot de passe sont similaires`
          : 'The old and the new password are similar',
    },
    newPassword: {
      error: true,
      errorMessage:
        lang === 'Français'
          ? `Le mot de passe doit respecter des exigences de complexité`
          : 'The password must comply with complexity requirements',
    },
    confirmPassword: {
      error: true,
      errorMessage:
        lang === 'Français'
          ? `Le mot de passe et sa confirmation ne sont pas identiques`
          : 'The password and its confirmation are not identical',
    },
  })

  const handleSubmit = async () => {
    await dispatch(
      changeTeacherPassword({
        ID_ENS: teacher?.ID_ENS,
        password: passwords.oldPassword,
        newPassword: passwords.newPassword,
        lang: lang,
      }),
    )
    if (!localStorage.getItem('teacherInfo')) refreshPage()

    //closeModalHandler()
  }
  function refreshPage() {
    window.location.reload(false)
  }

  const handleInputChange = (e) => {
    e.preventDefault()
    setPasswords({ ...passwords, [e.target.name]: e.target.value })
  }

  function validate_password(password) {
    let check = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    return password.match(check)
  }

  useEffect(() => {
    setFormValues({
      ...formValues,
      oldPassword:
        passwords?.oldPassword !== passwords?.newPassword || passwords?.oldPassword.trim() === ''
          ? {
              error: false,
              errorMessage:
                lang === 'Français'
                  ? `L'ancien et le nouveau mot de passe sont similaires`
                  : 'The old and the new password are similar',
            }
          : {
              error: true,
              errorMessage:
                lang === 'Français'
                  ? `L'ancien et le nouveau mot de passe sont similaires`
                  : 'The old and the new password are similar',
            },
      confirmPassword:
        passwords?.newPassword === passwords?.confirmPassword ||
        passwords?.confirmPassword.trim() === ''
          ? {
              error: false,
              errorMessage:
                lang === 'Français'
                  ? `Le mot de passe et sa confirmation ne sont pas identiques`
                  : 'The password and its confirmation are not identical',
            }
          : {
              error: true,
              errorMessage:
                lang === 'Français'
                  ? `Le mot de passe et sa confirmation ne sont pas identiques`
                  : 'The password and its confirmation are not identical',
            },
      newPassword:
        validate_password(passwords?.newPassword) || passwords?.newPassword.trim() === ''
          ? {
              error: false,
              errorMessage:
                lang === 'Français'
                  ? `Le mot de passe doit respecter des exigences de complexité`
                  : 'The password must comply with complexity requirements',
            }
          : {
              error: true,
              errorMessage:
                lang === 'Français'
                  ? `Le mot de passe doit respecter des exigences de complexité`
                  : 'The password must comply with complexity requirements',
            },
    })
  }, [passwords])

  const closeModalHandler = () => dispatch(closeModal())

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Grid container spacing={2} mb={2}>
          <Grid item md={6} xs={12}>
            <TextField
              label={lang === 'Français' ? 'Ancien mot de passe' : 'Old password'}
              name="oldPassword"
              fullWidth
              type={showOldPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowOldPassword(!showOldPassword)
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={passwords?.oldPassword || ''}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              onChange={handleInputChange}
              error={formValues.oldPassword.error}
              helperText={formValues.oldPassword.error && formValues.oldPassword.errorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={6} xs={12}>
            <TextField
              label={lang === 'Français' ? 'Nouveau mot de passe' : 'New password'}
              name="newPassword"
              fullWidth
              type={showNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowNewPassword(!showNewPassword)
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={passwords?.newPassword || ''}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              onChange={handleInputChange}
              error={formValues.newPassword.error}
              helperText={formValues.newPassword.error && formValues.newPassword.errorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={6} xs={12}>
            <TextField
              label={
                lang === 'Français'
                  ? 'Confirmer le nouveau mot de passe'
                  : 'Confirm the new password'
              }
              name="confirmPassword"
              fullWidth
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword)
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={passwords?.confirmPassword || ''}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              onChange={handleInputChange}
              error={formValues.confirmPassword.error}
              helperText={
                formValues.confirmPassword.error && formValues.confirmPassword.errorMessage
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={12} xs={12}>
            {lang === 'Français' ? (
              <>
                <Typography variant="body2">Votre mot de passe doit contenir :</Typography>
                <Typography variant="body2">- au minimum 8 caractères,</Typography>
                <Typography variant="body2">- au moins une lettre miniscule,</Typography>
                <Typography variant="body2">- au moins une lettre majuscule,</Typography>
                <Typography variant="body2">- au moins un chiffre,</Typography>
                <Typography variant="body2">- au moins un caractère spécial.</Typography>
              </>
            ) : (
              <>
                <Typography variant="body2">Your password must contain :</Typography>
                <Typography variant="body2">- at least 8 characters,</Typography>
                <Typography variant="body2">- at least one miniscule letter,</Typography>
                <Typography variant="body2">- at least one capital letter,</Typography>
                <Typography variant="body2">- at least one digit,</Typography>
                <Typography variant="body2">- at least one special character.</Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              size="small"
              onClick={handleSubmit}
              disabled={
                formValues?.newPassword.error ||
                formValues?.confirmPassword.error ||
                formValues?.oldPassword.error ||
                passwords.oldPassword.trim() === '' ||
                passwords.newPassword.trim() === '' ||
                passwords.confirmPassword.trim() === ''
              }
            >
              {lang === 'Français' ? 'Modifier' : 'Update'}
            </Button>
            <Button size="small" onClick={closeModalHandler}>
              {lang === 'Français' ? 'Fermer' : 'Close'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ChangePassword
